/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-11tdayw --style3 --full" anim={""} name={"intro"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--128 lh--1 mt--08" style={{"maxWidth":1192}} content={"<span style=\"color: white;\">Bitter and gold.<br>Like it should be.</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20" content={"Beer List"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--2 flex--center" anim={"4"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--left" style={{"maxWidth":""}} content={"Quality Czech Beer Brewed Locally & With Respect To Tradition"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":480}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":700}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/153/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/153/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/153/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/153/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/153/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/153/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-1"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--3 flex--center" anim={"4"} animS={"3"} style={{"maxWidth":1440}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":350}}>
              
              <Subtitle className="subtitle-box" content={"Light Lager"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":480}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":350}}>
              
              <Subtitle className="subtitle-box" content={"Dark Lager"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":480}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":350}}>
              
              <Subtitle className="subtitle-box" content={"Pale Ale"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":480}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-zhshp8 --style2 --full pb--60 pt--60" name={"contact"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert" content={"Unpasteurized.<br>Unfiltered.</br>Full of taste."}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20" content={"Beer List"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"information-1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 el--1 flex--center" anim={"4"} animS={"3"} style={{"maxWidth":1360}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">Reviews</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--3 flex--center" anim={"4"} animS={"3"} style={{"maxWidth":1360}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\"><span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":350}} content={"<span style=\"color: white;\">\"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.\"<br><br><span style=\"font-weight: bold;\">—   Jane Doe&nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-hglam1 pb--60 pt--60" name={"contact"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/153/fullscreen-3_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Got questions?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20" content={"Get in touch"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"}>
          
          <ColumnWrap className="column__flex --left el--2" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":449}}>
              
              <Subtitle className="subtitle-box" content={"Craft Beer"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"722 Finch Street<br>info@vase-stranky.com<br>510-851-40X4"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}